<template>
  <div class="inner" v-if="timeType != ''">
    <div class="under">
      <!-- 结束后的banner 图 -->
      <div
        class="banner"
        v-if="timeType == 'over' && $store.state.lang == 'zh'"
      >
        <!-- 223323 -->
        <img src="@/assets/banner-2x.png" alt="" />
      </div>
      <div
        class="banner"
        v-if="timeType == 'over' && $store.state.lang == 'en'"
      >
        <!-- 223323 -->
        <img src="@/assets/banner-en-2x.png" alt="" />
      </div>
      <div class="card" v-if="timeType != 'over'">
        <div class="top-card">
          <div class="left">
            <div class="one">
              {{ voteMessage.user_total }}
            </div>
            <div class="two">
              {{ $t('enroll.Number_of_Applicants') }}
            </div>
          </div>
          <div class="line"></div>
          <div class="right">
            <div class="one">
              {{ voteMessage.vote_total }}
            </div>
            <div class="two">
              {{ $t('enroll.Total_Votes') }}
            </div>
          </div>
        </div>
        <div class="time-card" v-if="time > 0">
          <van-count-down :time="time">
            <template #default="timeData">
              <span v-show="timeType == 'doing' && $store.state.lang == 'zh'"
                >距离投票结束只有</span
              >
              <span v-show="timeType == 'nostart' && $store.state.lang == 'zh'"
                >距离投票开始还有</span
              >
              <span class="block">{{ timeData.days }}</span>
              <span class="colon">{{
                Number(timeData.days) > 1 ? $t('enroll.Days') : $t('enroll.Day')
              }}</span>
              <span class="block">{{ timeData.hours }}</span>
              <span class="colon">{{
                Number(timeData.hours) > 1
                  ? $t('enroll.Hours')
                  : $t('enroll.Hour')
              }}</span>
              <span class="block">{{ timeData.minutes }}</span>
              <span class="colon">{{
                Number(timeData.minutes) > 1
                  ? $t('enroll.Minutes')
                  : $t('enroll.Minute')
              }}</span>
              <span v-show="timeType == 'doing' && $store.state.lang == 'en'">
                {{ $t('enroll.left_until_the_voting_ends') }}</span
              >
              <span v-show="timeType == 'nostart' && $store.state.lang == 'en'"
                >{{ $t('enroll.no_start_until') }}
              </span>
            </template>
          </van-count-down>
        </div>
      </div>
    </div>
    <div class="card-btn" v-if="timeType != 'over'">
      <div
        class="one"
        v-if="voteMessage.no_vote_list && voteMessage.no_vote_list.length > 2"
        @click="jumpNo"
      >
        {{ $t('enroll.Non_participating_address') }}
      </div>
      <div
        class="two"
        v-if="userInitData.is_sign_up == 0 || userInitData.is_sign_up == 1"
        @click="jumpEvent"
      >
        <span v-if="userInitData.is_sign_up == 0">{{
          $t('enroll.I_want_to_register')
        }}</span>
        <span v-if="userInitData.is_sign_up == 1">{{
          $t('enroll.My_Registration')
        }}</span>
      </div>
    </div>

    <div
      class="none-box"
      align="center"
      v-show="!showList || timeType == 'nostart'"
    >
      <img src="@/assets/none-vote-2x.png" alt="" />
      <div class="none">{{ $t('enroll.No_Votes') }}</div>
    </div>
    <div
      v-show="showList && timeType != 'nostart'"
      class="has-msg"
      :style="timeType == 'over' ? 'margin-top:70px;' : ''"
    >
      <div
        class="you-agree"
        v-if="timeType == 'doing' && userInitData.is_vote == 1"
      >
        <span v-show="$store.state.lang == 'zh'">
          您已投给{{ userInitData.vote_data.name }}
          <span style="color:#0073EE;">{{
            userInitData.vote_data.vote_number
          }}</span
          >{{ userInitData.vote_data.vote_type == 1 ? '赞同' : '反对' }}票</span
        >

        <span v-show="$store.state.lang == 'en'">
          You have submitted
          <span
            :style="
              userInitData.vote_data.vote_type == 1
                ? 'color:#0073EE;'
                : 'color:#F96B1C'
            "
            >{{ userInitData.vote_data.vote_number }}</span
          >
          {{
            userInitData.vote_data.vote_type == 1 ? 'approval' : 'disapproval'
          }}
          vote for {{ userInitData.vote_data.name }}
        </span>
      </div>
      <div class="search-box">
        <van-search
          v-model="searchInfo"
          show-action
          :placeholder="$t('enroll.Please_search_for_a_name')"
          @search="onSearch"
          left-icon="search"
          size="30px"
          @focus="isFocus"
          @blur="isblur"
        >
          <template #action>
            <div class="search-btn" @click="onSearch">
              {{ $t('enroll.Search') }}
            </div>
          </template>
          <template #left-icon>
            <van-icon name="search" size="22px" color="#777789" />
          </template>
        </van-search>
      </div>
      <!-- 投票的列 -->
      <div class="vote-list" v-if="isSearching">
        <van-list
          v-model="loading"
          :finished="finished"
          :finished-text="$t('index.no_more')"
          @load="onLoad"
          :offset="300"
          :loading-text="$t('tip.loading')"
          style="display:flex;flex-wrap:wrap; justify-content:space-between;"
        >
          <van-cell
            v-for="(item, index) in listArr"
            :key="index"
            @click="jumpDetail(item)"
            style="width: 31.07%;margin-top:15px;border-radius: 8px;"
          >
            <div class="vote-info">
              <div class="img-box">
                <img :src="item.img_url" alt="" />
              </div>
              <div class="name">
                <span>{{ item.name }}</span>
              </div>
              <div class="agree" v-if="timeType != 'over'">
                <img src="@/assets/agree-2x.png" alt="" />
                <div style="color:#0073EE">{{ item.agree }}</div>
              </div>
              <div class="dis-agree" v-if="timeType != 'over'">
                <img src="@/assets/disagree-2x.png" alt="" />
                <div style="color:#F96B1C">{{ item.oppose }}</div>
              </div>
            </div>
          </van-cell>
          <div style="width: 31.07%;margin-top:15px;border-radius: 8px;"></div>
        </van-list>
      </div>
    </div>
    <div style="height:80px;"></div>
    <tabbar value="two" v-show="showTabbar"></tabbar>
  </div>
</template>

<script>
import { Toast } from 'vant'
import tabbar from '@/components/tabbar'
import { getList, getInitData, getConfig } from '@/api/enroll'
export default {
  components: {
    tabbar,
  },
  data() {
    return {
      time: 0,
      type: 2,
      searchInfo: '',
      loading: false,
      finished: false,
      getObj: {
        limit: 9,
        start: 0,
        search: '',
      },
      listArr: [],
      voteMessage: {},
      timeType: '',
      userInitData: {},
      showList: true,
      isSearching: true,
      showTabbar: true,
    }
  },
  methods: {
    isFocus() {
      this.showTabbar = false
    },
    isblur() {
      this.showTabbar = true
    },
    jumpNo() {
      this.$router.push('/noVote')
    },
    jumpEvent() {
      if (this.timeType == 'nostart') {
        Toast.fail(this.$t('enroll.Registration_has_not_started'))
        return
      }
      if (this.userInitData.is_sign_up == 0) {
        this.$router.push('/enroll')
      } else if (this.userInitData.is_sign_up == 1) {
        this.$router.push({
          path: 'information',
          query: { account: this.$store.state.account.name },
        })
      }
    },
    // 获取用户初始因袭
    async getUserInit() {
      if (!this.$store.state.account.name) {
        Toast(this.$t('tip.your_main_account_not_found'))
        return
      }
      let res = await getInitData({ account: this.$store.state.account.name })
      console.log(res)
      if (res.code == 0) {
        this.userInitData = res.data
      }
    },
    // 获取首页统计项
    async getConfigMsg() {
      let res = await getConfig()
      if (res.code == 0) {
        this.voteMessage = res.data
        if (Number(res.data.start_time + '000') > new Date().getTime()) {
          this.time = Number(res.data.start_time + '000') - new Date().getTime()
          this.timeType = 'nostart'
          // 投票尚未开始
          return
        } else if (Number(res.data.end_time + '000') > new Date().getTime()) {
          console.log(1657796889 + '000')
          console.log(new Date().getTime())
          // 投票进行中
          this.time = Number(res.data.end_time + '000') - new Date().getTime()
          this.timeType = 'doing'
        } else {
          // 投票结束
          this.timeType = 'over'
        }
      }
    },

    // 跳转到投票详情页面
    jumpDetail(item) {
      if (this.timeType == 'over') {
        return
      }
      this.$router.push({
        path: 'information',
        query: { account: item.account },
      })
    },
    onSearch() {
      this.isSearching = false
      this.listArr = []
      this.getObj = {
        limit: 9,
        start: 0,
        search: this.searchInfo,
      }
      this.loading = false
      this.finished = false
      setTimeout(() => {
        this.isSearching = true
      }, 0)
    },
    // 请求列表的数据
    async onLoad() {
      this.fetchData()
      setTimeout(() => {
        this.getObj.start += 9
      })
    },
    // 获取数据
    async fetchData() {
      let res = await getList(this.getObj)
      if (res.data.total > 0) {
        this.showList = true
      } else if (res.data.total == 0) {
        this.showList = false
      }
      this.loading = false
      this.listArr.push(...res.data)
      if (
        this.listArr.length == res.total ||
        res.data.length < this.getObj.limit
      ) {
        this.finished = true
        return
      }
    },
  },
  created() {
    this.getConfigMsg()
    this.getUserInit()
    console.log()
  },
}
</script>

<style lang="less" scoped>
/deep/ .van-field__control {
  line-height: 21px;
}
/deep/ .van-list__finished-text {
  width: 100%;
  margin: 0 auto;
}
/deep/ .van-list__loading {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}
/deep/ .van-cell {
  padding: 0px;
  line-height: normal;
}
/deep/ .van-search {
  padding: 0;
  border-radius: 25px;
  border: 1px solid #e1e2e5;
  background-color: #fff;
  height: 45px;
}
/deep/ .van-search__content {
  border-radius: 25px;
  background-color: #fff;
}

/deep/ .van-count-down {
  font-size: 12px;
  line-height: 12px;
  color: #777789;
  .block {
    color: #0073ee;
  }
}
.search-btn {
  width: 70px;
  height: 35px;
  background: #0073ee;
  border-radius: 25px;
  text-align: center;
  line-height: 35px;
  color: #fff;
}
.inner {
  background: #eef2f6;
  min-height: 100vh;
  .under {
    height: 160px;
    background-color: #0f1434;
    position: relative;
    // banner 图
    .banner {
      position: absolute;
      z-index: 2;
      width: 93.3333%;
      transform: translateX(-50%);
      left: 50%;
      top: 25px;
      height: 187px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .card {
      width: 93.3333%;
      position: absolute;
      bottom: 0;
      transform: translateX(-50%);
      left: 50%;
      height: 134px;
      background-color: #fff;
      border-radius: 8px 8px 0px 0px;
      box-sizing: border-box;
      padding: 32px 10px 0px 10px;
      border-bottom: 1px solid #f2f2f2;
      .top-card {
        display: flex;
        justify-content: space-between;
        height: 76px;
        .left,
        .right {
          width: 50%;
          min-height: 43px;
          .one {
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #181a33;
          }
          .two {
            text-align: center;
            margin-top: 10px;
            font-size: 14px;
            color: #777789;
            word-wrap: break-word;
            word-break: break-all;
          }
        }
        .right {
          width: 50%;
          min-height: 43px;
        }
        .line {
          width: 1px;
          height: 45px;
          background-color: #f2f2f2;
        }
      }
      .time-card {
        text-align: center;
        color: #777789;
        font-size: 12px;
        height: 25px;
        display: flex;
        justify-content: center;
      }
    }
  }
  .card-btn {
    width: 93.3333%;
    height: 52px;
    margin: 0 auto;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    text-align: center;
    box-shadow: 0px 4px 9px 0px rgba(0, 0, 0, 0.05);
    border-radius: 0px 0px 8px 8px;

    .one,
    .two {
      width: 100%;
      text-align: center;
      color: #0073ee;
      line-height: 52px;
    }
  }
  .none-box {
    margin-top: 126px;
    img {
      width: 82px;
      height: 82px;
      margin: 0 auto;
    }
    .none {
      margin-top: 12px;
      text-align: center;
      color: #838383;
      font-size: 14px;
    }
  }
  .has-msg {
    padding-left: 15px;
    padding-right: 15px;
    box-sizing: border-box;
    margin-top: 24px;
    .you-agree {
      font-size: 12px;
      margin-bottom: 15px;
    }
  }
  .vote-list {
    margin-top: 2px;
    padding-bottom: 100px;
    .vote-info {
      // height: 197px;
      box-sizing: border-box;
      padding-top: 4px;
      padding-left: 4px;
      padding-right: 4px;
      padding-bottom: 5px;
      background-color: #fff;
      .img-box {
        width: 100%;
        height: 102px;
        border-radius: 6px;
        max-width: 102px;
        margin: 0 auto;
        img {
          width: 100%;
          height: 100%;
          border-radius: 6px;
        }
      }
      .name {
        padding-left: 5px;
        margin-top: 5px;
        font-size: 14px;
        font-weight: 400;
        color: #181a33;
        height: 39px;
        display: flex;
        align-items: center;
        align-content: center;
        margin-bottom: 5px;
      }
      .agree,
      .dis-agree {
        display: flex;
        padding-left: 5px;
        padding-right: 5px;
        font-size: 12px;
        align-items: center;
        margin-bottom: 9px;
        img {
          width: 16px;
          height: 16px;
        }
        div {
          // overflow: hidden;
          // white-space: nowrap;
          // text-overflow: ellipsis;
          box-sizing: border-box;
          margin-left: 4px;
        }
      }
    }
  }
}
</style>
